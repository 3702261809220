<template>
  <router-view></router-view>
  <RightButtom />
</template>

<script>
import RightButtom from "./components/RightButtom.vue";
export default {
  name: "App",
  components: {
    RightButtom,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}
/* html {
  overflow-x: hidden !important;
} */
</style>
