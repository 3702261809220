import { createRouter, createWebHistory } from "vue-router";

const routes = [
    //首页
    {
        path: "/",
        name: "Home",
        component: () =>
            import ("../pages/Home.vue"),
        meta: {
            keepAlive: true,
        },
    },
    //自研产品
    {
        path: "/selfProduct",
        name: "SelfProduct",
        component: () =>
            import ("../pages/SelfProduct.vue"),
        meta: {
            keepAlive: true,
        },
    },
    // 解决方案
    {
        path: "/programme",
        name: "Programme",
        component: () =>
            import ("../pages/Programme.vue"),
        meta: {
            keepAlive: true,
        },
    },
    // 合作案例
    {
        path: "/cooperate",
        name: "Cooperate",
        component: () =>
            import ("../pages/Cooperate.vue"),
        meta: {
            keepAlive: true,
        },
    },
    // 关于我们
    {
        path: "/about",
        name: "About",
        component: () =>
            import ("../pages/About.vue"),
        meta: {
            keepAlive: true,
        },
    },
    // 详情页面
    {
        path: "/details",
        name: "Details",
        component: () =>
            import ("../pages/Details.vue"),
        props($route) {
            return { id: $route.query.id, source: $route.query.source };
        },
        meta: {
            keepAlive: true,
        },
    },
    // 公司详情
    {
        path: "/companyDetails",
        name: "CompanyDetails",
        component: () =>
            import ("../pages/CompanyDetail.vue"),
        meta: {
            keepAlive: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    //点击浏览器的刷新按钮 回到顶部
    scrollBehavior(to, from, savedPosition) {
        return {
            x: 0,
            y: 0,
        };
    },
});
//点击浏览器的返回按键 页面回到顶部
router.beforeEach((to, from, next) => {
    console.log(to.query);
    console.log(Object.keys(to.query).length);
    if (to.query && Object.keys(to.query).length > 0) {
        console.log(to, from, next);
        // 如果路由带有参数，直接允许跳转
        next();
    } else {
        // 如果路由没有参数，执行其他操作（例如滚动条位置重置）并允许跳转
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        next();
    }
});

export default router;