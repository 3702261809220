import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import $ from 'jquery'
import "bootstrap/dist/css/bootstrap.min.css"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/utils/style.css'
import '@/mixins/ScrollAnimationMixin.js'
import router from './router'
import 'matchmedia-polyfill'


createApp(App).use($).use(ElementPlus).use(router).mount('#app')