<template>
  <div class="right-buttom">
    <div
      class="imgCall"
      :class="{ expanded: phoneIsHidden }"
      :style="{ right: phoneIsHidden ? '0px' : '-106px' }"
      @mouseenter="onEnterTd(10)"
      @mouseleave="onLeaveTd(10)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="currentColor"
        class="bi bi-telephone-forward-fill"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zm10.761.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708"
        />
      </svg>
      <span>135-2464-1873</span>
    </div>
    <div
      class="imgWechat"
      @mouseenter="onEnterTd(11)"
      @mouseleave="onLeaveTd(11)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="33"
        fill="currentColor"
        class="bi bi-wechat"
        viewBox="0 0 16 16"
      >
        <path
          d="M11.176 14.429c-2.665 0-4.826-1.8-4.826-4.018 0-2.22 2.159-4.02 4.824-4.02S16 8.191 16 10.411c0 1.21-.65 2.301-1.666 3.036a.32.32 0 0 0-.12.366l.218.81a.6.6 0 0 1 .029.117.166.166 0 0 1-.162.162.2.2 0 0 1-.092-.03l-1.057-.61a.5.5 0 0 0-.256-.074.5.5 0 0 0-.142.021 5.7 5.7 0 0 1-1.576.22M9.064 9.542a.647.647 0 1 0 .557-1 .645.645 0 0 0-.646.647.6.6 0 0 0 .09.353Zm3.232.001a.646.646 0 1 0 .546-1 .645.645 0 0 0-.644.644.63.63 0 0 0 .098.356"
        />
        <path
          d="M0 6.826c0 1.455.781 2.765 2.001 3.656a.385.385 0 0 1 .143.439l-.161.6-.1.373a.5.5 0 0 0-.032.14.19.19 0 0 0 .193.193q.06 0 .111-.029l1.268-.733a.6.6 0 0 1 .308-.088q.088 0 .171.025a6.8 6.8 0 0 0 1.625.26 4.5 4.5 0 0 1-.177-1.251c0-2.936 2.785-5.02 5.824-5.02l.15.002C10.587 3.429 8.392 2 5.796 2 2.596 2 0 4.16 0 6.826m4.632-1.555a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0m3.875 0a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0"
        />
      </svg>
    </div>
    <div class="imgTop" @click="toTop">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="currentColor"
        class="bi bi-arrow-up-circle-fill"
        viewBox="0 0 16 16"
      >
        <path
          d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"
        />
      </svg>
    </div>
    <div class="wechat" v-bind:hidden="!wechatIsHidden">
      <img src="../assets/code.jpg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "RightButtom",
  data() {
    return {
      phoneIsHidden: false,
      wechatIsHidden: false,
    };
  },
  methods: {
    //鼠标输入
    onEnterTd(index) {
      console.log("移入", index);
      if (index == 10) {
        this.phoneIsHidden = true;
        this.wechatIsHidden = false;
      } else if (index == 11) {
        this.wechatIsHidden = true;
        this.phoneIsHidden = false;
      }
    },
    //鼠标移出
    onLeaveTd(index) {
      console.log("移除", index);
      if (index == 10) {
        this.phoneIsHidden = false;
      } else if (index == 11) {
        this.wechatIsHidden = false;
      }
    },
    //回到顶部
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 900px) {
  .right-buttom {
    display: none !important;
  }
}
.right-buttom {
  position: fixed;
  top: 40%;
  right: 0;
  z-index: 999;
}
.imgCall {
  width: 160px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(20, 32, 56);
  position: fixed;
  top: 40%;
  z-index: 999;
  right: -106px;
  transition: right 0.5s;
  cursor: pointer;
  border: none;
}
.imgCall.expanded {
  width: 160px;
}
.imgWechat {
  width: 54px;
  height: 54px;
  display: flex;
  margin-top: 54px;
  justify-content: center;
  align-items: center;
  background-color: rgb(20, 32, 56);
}
.imgTop {
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(20, 32, 56);
}
span {
  color: white;
  font-size: 14px;
  margin-left: 10px;
}
.wechat {
  width: 130px;
  height: 130px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  top: 30%;
  right: 70px;
  z-index: 999;
  background-color: rgb(20, 32, 56, 0.8);
}
img {
  width: 110px;
  height: 110px;
}
</style>
