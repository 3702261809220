export const ScrollAnimationMixin = {
    data() {
        return {
            showDiv: false,
        };
    },
    methods: {
        handleScroll() {
            console.log(this.$refs.animatedDiv)
            console.log(window.pageYOffset)
            const currentScroll = window.pageYOffset;
            const targetRef = this.$refs.animatedDiv;
            if (targetRef) {
                const targetOffset = targetRef.offsetTop;
                const windowHeight = window.innerHeight;
                console.log("targetOffset", targetOffset)
                console.log("windowHeight", windowHeight)
                if (currentScroll >= targetOffset - windowHeight) {
                    this.showDiv = true;
                }
            } else {
                this.showDiv = true;
            }
        }
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
};